module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#000","display":"minimal-ui","icon":"src/themes/siemens/icon.png","icons":[{"src":"/facicon.ico","sizes":"16x16","type":"image/x-icon"},{"src":"/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"ICON","sizes":"48x48","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"24eb31abef52d13e6d83c31c8285bec5"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"matomoUrl":"//analytics.cuenect.de/","siteId":"28","siteUrl":"https://smartbuildingsconnect.virtualevent.siemens.com/","dev":false,"requireConsent":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
