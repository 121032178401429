import styled from '@emotion/styled'
import React from 'react'
import { mq } from './../../../utility/breakpoint'

interface KeyVisualProps {
  extraLayer?: boolean
}

export const KeyVisual: React.FC<KeyVisualProps> = ({
  children,
  extraLayer,
}) => {
  return (
    <KeyVisualContainer>
      <GradientTop />
      <GradientLeft />
      {extraLayer && <GradientExtra />}
      <KeyVisualContent>{children}</KeyVisualContent>
    </KeyVisualContainer>
  )
}
export const KeyVisualContainer = styled.div(
  ({ theme: { grid } }) => `
  background:url(/assets/key-visual.jpg) center center / cover;
  height:100vh;
  width:100%;
  grid-template-rows:1fr;
  p{
    text-shadow: 0px 0px 5px rgba(255, 255, 255, .6);
    ${mq[3]} {
      text-shadow:none;
    }
  }`
)

export const KeyVisualContent = styled.div(
  ({ theme: { grid } }) => `
  position:absolute;
  left:0;
  top:0;
  height:100vh;
  width:100%;`
)

export const GradientExtra = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: #fff;
  ${mq[3]} {
    display: none;
  }
`

export const GradientTop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 33vh;
  opacity: 0.5;
  background: transparent
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat;
`

export const GradientLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 66vw;
  height: 100vh;
  opacity: 0.8;
  background: transparent
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat;
`
