import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'

interface InputProps extends HTMLInputElement {
  error: string | undefined
  label?: string | undefined
}

interface InputContainerProps {
  placeholder?: string | undefined
  label?: string | undefined
  focus?: boolean
  error?: boolean
}

export const Input = React.forwardRef<
  InputProps,
  JSX.IntrinsicElements['input']
>(({ ...rest }, ref) => {
  const [placeholder, setPlaceholder] = React.useState<string | undefined>(
    rest.label
      ? rest.label
      : rest.value !== '' && rest.value !== undefined
      ? rest.placeholder
      : ''
  )
  const [focus, setFocus] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (rest.value) {
      setPlaceholder(rest.label || rest.placeholder)
    }
  }, [rest.value])

  return (
    <InputContainer
      placeholder={placeholder}
      focus={focus}
      error={rest.error && rest.error !== ''}
    >
      <InputElement
        className={rest.error && rest.error !== '' ? 'error' : ''}
        ref={ref}
        {...rest}
        onFocus={() => {
          rest.onFocus && rest.onFocus()
          setFocus(true)
          setPlaceholder(rest.label || rest.placeholder)
        }}
        onBlur={e => {
          rest.onBlur && rest.onBlur()
          setFocus(false)
          setPlaceholder(e.currentTarget.value ? placeholder : '')
        }}
      />
      {rest.error && <Error>{rest.error}</Error>}
    </InputContainer>
  )
})

export const InputContainer = styled.div<InputContainerProps>(
  ({ theme: { colors, fonts }, label, placeholder, focus, error }) => `
  position:relative;
  &:after{
    color:${
      error
        ? colors.error
        : focus
        ? colors.inputBorderHover
        : colors.inputBorder
    };
    content: "${label || placeholder}";
    position:absolute;
    font-size:12px;
    font-family: ${fonts.fontFamily};
    left:16px;
    top:2px;
  }
`
)

export const InputElement = styled.input(
  ({ theme: { colors, fonts } }) => `
  font-family: ${fonts.fontFamily};
  font-size:18px;
  padding:19px 16px 10px 16px;
  border:none;
  outline:none;
  background:${colors.inputBackground};
  border-bottom:1px solid ${colors.inputBorder};
  color:${colors.inputText};
  box-sizing: border-box;
  &:disabled{
    border-bottom:none;
    color:${rgba(colors.inputText, 0.5)};
  }
  &:enabled:hover, &:enabled:focus{
    background:${colors.inputBackgroundHover};
    border-bottom:1px solid ${colors.inputBorderHover};
  }
  &::placeholder{
    transform:translateY(-5px);
    color:${colors.inputBorder}
  }
  &:focus{
    background:${colors.inputBackgroundActive};
    &::placeholder{font-size:0;  }
  }
  &.error{
    background:${rgba(colors.error, 0.2)};
    border-bottom:1px solid ${colors.error};
  }
  `
)
export const Error = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
  color:${colors.error};
  font-size:12px;
  padding:3px 16px 0 16px;
`
)
