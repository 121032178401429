import { AxiosResponse } from 'axios'
import { LocalStorageUtil, TimeUtil } from 'cuenect-web-core'
import { apiConfig, EventBreakEvent, eventBreaks } from './../config'
import { HttpService } from './http'

export interface ProgramResponse {
  time: string
  schedules: Array<Schedule>
}

export interface Schedule {
  start: string
  end: string
  id: string
  slug: string
  events: Array<ProgramEvent>
  title?: string
  interval?: number
}

export interface ProgramCategory {
  id: string
  slug: string
  title: string
}

export interface GroupedProgramCategory {
  type: string
  filters: ProgramCategory[]
}
export interface ProgramParticipants {
  signUp: boolean
  capacityRemaining: number
}

export interface ProgramEvent {
  id: string
  contentId: string
  title: string
  slug: string
  subtitle: string | null
  description: string
  startPlayback?: string
  start: string
  end: string
  category: string | null
  room: string | null
  language: string
  hidden: boolean
  shareable: boolean
  categories?: ProgramCategory[]
  participants: ProgramParticipants | null
  persons?: Array<{
    id: string
    name: string
    company: string
    occupation: string
    type: string
    profileImageURL: string
  }>
  links?: Array<ProgramLinks>
  special?: boolean
  isBreak?: boolean
  room?: ProgramCategory[] | null
  eventSlug?: string | null
  isAppointment?: boolean
  meeting?: string
}

export interface ProgramLinks {
  type: string
  href: string
  title: string | null
}

export interface ProgramLinks {
  type: string
  href: string
  title: string | null
}

export interface Participations {
  [key: string]: { meetingURL: string }
}

/*
const getContent = async () => {
  const eventParticipations = await ProgramService.eventParticipation()
  const eventParticipationsKeys: string[] = Object.keys(
    eventParticipations
  ).map(k => k)
  setEventParticipations(eventParticipationsKeys)
  setAppointmentLoaded(true)
} */

const APPOINTMENT_REQUEST_TIMESTAMP = 'appointmentRequestTimestamp'
export class ProgramService {
  public static async getProgram(
    source: string,
    slug?: string | Array<string>,
    showHidden?: boolean,
    staticSource?: boolean
  ): Promise<ProgramEvent[]> {
    let scheduleResult
    let dataResult
    if (!staticSource) {
      const result: AxiosResponse = await HttpService.get({ url: source })
      const { data }: { data: ProgramResponse } = result
      const { schedules }: { schedules: Array<Schedule> } = data
      scheduleResult = schedules
      dataResult = data
    } else {
      dataResult = source
      scheduleResult = source.schedules
    }

    if (typeof slug === 'string') {
      const scheduleForTheLanguage = scheduleResult.find(
        (schedule: Schedule) => schedule.slug === slug
      )
      const res =
        (scheduleForTheLanguage && scheduleForTheLanguage.events) ||
        ([] as Array<ProgramEvent>)

      /* // inject Breaks
      const eventsBreak = eventBreaks.find(
        ({ slug: breakSlug }) => breakSlug === slug
      )
      if (eventsBreak) {
        return [...res, ...eventsBreak.events].filter(({hidden})=>!hidden)
      } */
      if (showHidden) {
        return res
      }

      return res
        .map(ev => ({ ...ev, eventSlug: slug }))
        .filter(({ hidden }) => !hidden)
    }

    let ret: ProgramEvent[] = []
    dataResult.schedules.forEach(event => {
      const events = event.events.map(ev => ({ ...ev, eventSlug: event.slug }))
      ret = [...ret, ...events]
    })
    if (showHidden) {
      return ret
    }

    return ret.filter(({ hidden }) => !hidden)
  }

  public static async reserveEvent(id: string) {
    return await HttpService.post({
      url: `${apiConfig.registerEvent}/${id}/register`,
      config: { withCredentials: true },
    })
  }

  public static async cancelEvent(id: string) {
    return await HttpService.post({
      url: `${apiConfig.registerEvent}/${id}/unregister`,
      config: { withCredentials: true },
    })
  }

  public static async getAppointments() {
    return await HttpService.get({ url: `${apiConfig.appointments}` })
  }

  public static async eventParticipation() {
    const result: AxiosResponse | false = await HttpService.get({
      url: `${apiConfig.eventParticipation}`,
      config: { withCredentials: true },
    })
    if (!result) {
      return false
    }

    return result.data
  }

  public static async hasParticipation(override: boolean) {
    let call = false
    let hasPart = false
    let eventParticipations = {}
    const ls = LocalStorageUtil.getObject(APPOINTMENT_REQUEST_TIMESTAMP) || {
      lastTime: '',
    }
    if (!ls.lastTime) {
      call = true
    } else {
      const lastCall = TimeUtil.getUtc(ls.lastTime)
      hasPart = ls.hasParticipation
      // Check State only every hour
      if (
        TimeUtil.getDuration(TimeUtil.getNowUtc().diff(lastCall)).asHours() > 1
      ) {
        call = true
      }
    }

    if (override) {
      call = true
    }

    if (call) {
      eventParticipations = await ProgramService.eventParticipation()
      hasPart =
        Object.keys(eventParticipations).length !== 0 &&
        eventParticipations.constructor === Object

      LocalStorageUtil.saveData(APPOINTMENT_REQUEST_TIMESTAMP, {
        lastTime: TimeUtil.getNowUtc().format(),
        hasParticipation: hasPart,
      })
    }

    return { hasPart, eventParticipations }
  }
}
